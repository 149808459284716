import type { PropsWithChildren, ReactNode } from 'react'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import type { TestIds } from '../../../utils'
import { Text } from '../../text'

export const PageHeaderSubtitle = ({
  subtitle,
  textColor = 'info',
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<
  { subtitle: ReactNode; textColor?: ThemeTextColor } & TestIds
>) => {
  return (
    <div className="flex gap-4">
      <Text
        textColor={textColor}
        fontSize="med"
        dataTestId={dataTestId}
        dataTrackingId={dataTrackingId}
      >
        {subtitle}
      </Text>
    </div>
  )
}
